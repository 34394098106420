import {Link, useMediaQuery} from '@mui/material'
import {graphql} from 'gatsby'
import {GatsbyImageFixedProps, GatsbyImageFluidProps} from 'gatsby-image'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import {
  AboutUsSectionTemplate,
  AdvantagesSectionTemplate,
  ContactSectionTemplate,
  ErrorMessage,
  HowItWorksSectionTemplate,
  IntroSectionTemplate,
} from '../components'
import {CookieConsentProvider} from '../context'
import {PageLayout} from '../layout'

type LoadedFluidImageProps = {childImageSharp: GatsbyImageFluidProps}
type LoadedFixedImageProps = {childImageSharp: GatsbyImageFixedProps}

export interface IndexPageProps {
  data?: {
    site: {siteMetadata: {title: string; description: string}}
    phoneFrameLight: LoadedFluidImageProps
    phoneFrameDark: LoadedFluidImageProps
    phoneFrameEmpty: LoadedFluidImageProps
    appStoreBadge: LoadedFixedImageProps
    googlePlayBadge: LoadedFixedImageProps
    storeUrls: {frontmatter: {appStoreUrl?: string; googlePlayUrl?: string}}
    generalSettings: {
      frontmatter: {phoneNumber: string}
    }
    localizedSettings: {
      frontmatter: {phoneNumber: string}
    }
  }
  error?: Error
}

export const IndexPage: React.FC<IndexPageProps> = ({data, error}) => {
  const {language} = useI18next()
  const {t} = useTranslation()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data) {
    return (
      <ErrorMessage>
        Your application has no available content. Please add some on the{' '}
        <Link href="/admin">admin panel</Link> first.
      </ErrorMessage>
    )
  }

  return (
    <PageLayout
      seo={{
        title: data.site.siteMetadata.title,
        siteName: data.site.siteMetadata.title,
        description: data.site.siteMetadata.description,
        lang: language,
      }}
      footerProps={{
        phoneNumber: data.localizedSettings?.frontmatter?.phoneNumber,
      }}
      t={t}
    >
      <CookieConsentProvider
        language={language}
        title={t('cookie.consent.title')}
        body={t('cookie.consent.body')}
        acceptLabel={t('cookie.consent.agree')}
        rejectLabel={t('cookie.consent.decline')}
        linkLabel={t('cookie.consent.link')}
      />

      <IntroSectionTemplate
        id="intro"
        image={
          prefersDarkMode
            ? data.phoneFrameDark.childImageSharp
            : data.phoneFrameLight.childImageSharp
        }
        appStoreBadge={data.appStoreBadge.childImageSharp}
        googlePlayBadge={data.googlePlayBadge.childImageSharp}
        appStoreUrl={data.storeUrls.frontmatter.appStoreUrl}
        googlePlayUrl={data.storeUrls.frontmatter.googlePlayUrl}
      />

      <AdvantagesSectionTemplate id="advantages" />

      <HowItWorksSectionTemplate
        id="how-it-works"
        image={data.phoneFrameEmpty.childImageSharp}
      />

      <AboutUsSectionTemplate id="about-us" />
      <ContactSectionTemplate
        id="contact"
        phoneNumber={
          data.localizedSettings?.frontmatter?.phoneNumber ||
          data.generalSettings?.frontmatter?.phoneNumber ||
          ''
        }
      />
    </PageLayout>
  )
}

export const query = graphql`
  query IndexPageQuery($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    storeUrls: markdownRemark(frontmatter: {templateKey: {eq: "store-links"}}) {
      frontmatter {
        appStoreUrl
        googlePlayUrl
      }
    }
    generalSettings: markdownRemark(
      frontmatter: {templateKey: {eq: "general-settings"}}
    ) {
      frontmatter {
        phoneNumber
      }
    }
    localizedSettings: markdownRemark(
      frontmatter: {
        templateKey: {eq: "localized-settings"}
        language: {eq: $language}
      }
    ) {
      frontmatter {
        phoneNumber
      }
    }
    phoneFrameLight: file(
      relativePath: {eq: "finastic-iphone-frame-light.png"}
    ) {
      childImageSharp {
        fluid(maxWidth: 285, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    phoneFrameDark: file(relativePath: {eq: "finastic-iphone-frame-dark.png"}) {
      childImageSharp {
        fluid(maxWidth: 285, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    phoneFrameEmpty: file(relativePath: {eq: "empty-iphone-frame.png"}) {
      childImageSharp {
        fluid(maxWidth: 285, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    appStoreBadge: file(relativePath: {eq: "app-store-badge.png"}) {
      childImageSharp {
        fixed(height: 53) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    googlePlayBadge: file(relativePath: {eq: "google-play-badge.png"}) {
      childImageSharp {
        fixed(height: 53) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
